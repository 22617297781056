<template>
  <div>
    <heHander></heHander>
    <div class="content-box">
      <div class="content-warrp">
        <el-carousel
          height="630px"
          trigger="click"
          arrow="never"
          ref="bannerRef"
          :autoplay="false"
        >
          <el-carousel-item v-for="item in bannerList" :key="item.id">
            <video
              :controls="true"
              v-if="item.type == 'video'"
              preload
              loop
              muted
              :autoplay="true"
              style="width: 100%; height: 100%"
            >
              <source :src="item.img" type="video/mp4" />
            </video>
            <img v-else class="img-item" :src="item.img" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="mlist-zj animate__animated animate__slideInUp" id="z1">
        <div class="content animate__animated animate__slideInUp">
          <div
            class="content-item animate__animated animate__slideInUp"
            v-for="item in chanpingList"
            :key="item.id"
            @click="toDetail(item)"
          >
            <div class="img-box">
              <img :src="item.img" alt="" />
            </div>

            <div class="text-box">
              <h3>{{ item.title }}</h3>
              <p v-html="item.text"></p>
            </div>
            <div class="more">Read more ></div>
          </div>
        </div>
      </div>
      <div class="mcustomize-zj">
        <div
          class="mcustomize-content"
          id="z2"
          :class="scrollTop > 0 ? classStyle('z2') : ''"
        >
          <div class="left">
            <img
              src="./image.jpg"
              alt=""
            />
          </div>
          <div class="right">
            <h1>关于我们 <span> About us</span></h1>
            <p>
              纬拓科技致⼒于3D数字化系统整合应⽤服务，专业团队提供完整的解决方案，从汽车、船舶、机械自动化等工业制造应用，到文博、工艺品、时尚娱乐、日常消费品等与我们息息相关的应用，提供3D数字化设计
              、开发、制造全⽅位的技术服务，为客户缩短产品开发周期，极大提升产业效益。我们拥有专业研发团队进⾏相关3D软件开发，包扩数字化在线展示平台、在线商城、线下展示等多方面应用。
              公司拥有专业的技术团队和充沛的3D扫描设备资源，为您提供最专业的3D扫描&逆向工程服务。根据您的扫描物件特征及设计需求，选择符合行业及客户应用需求的3D扫描&逆向⽅案。相较于传统的3D建模，3D扫描可快速获取实物的3D模型资料，可应用于3D数字模型展示、逆向工程服务、
              数字化检测等多个方向和领域，满足不同行业和客户的需求。
            </p>
          </div>
        </div>
      </div>
      <div
        class="model-zj mlist-zj"
        id="z3"
        :class="scrollTop > 0 ? classStyle('z3') : ''"
      >
        <div class="top">
          <div class="top-left">
            <h1>案例展示</h1>
            <span> Case</span>
          </div>
          <!-- <div class="top-right">
            <div
              class="right-item"
              :class="index == rightIndex ? 'active' : ''"
              v-for="(item, index) in M_rightList"
              :key="item.id"
              @click="rightClick1(index)"
            >
              {{ item.title }}
            </div>
          </div> -->
        </div>
        <div class="model-content">
          <div class="content-list" id="content-list">
            <div
              class="list-item"
              v-for="item in list"
              :key="item.id"
              @click="toDetailCase"
            >
              <img
                :src="item.img"
                alt=""
              />
              <div class="bottom">
                <h1>{{item.title}} </h1>
                <!-- <h3>Blueair Classic</h3> -->
                <p>
                  一位雕刻家决定使用3D扫描来完成新雕塑的每一步工作，从概念阶段直到最终成品。
                </p>
              </div>
              <div class="quan"><i class="el-icon-arrow-right"></i></div>
            </div>
          </div>
        </div>
        <div class="arrow-quan">
          <div
            class="quan-item"
            @click="tabQuan(index)"
            :class="index == tabIndex ? 'activeQuan' : ''"
            v-for="(item, index) in 2"
            :key="item"
          ></div>
        </div>
      </div>
      <!-- 服务支持 -->
      <!-- <div
        class="service"
        id="z4"
        :class="scrollTop > 0 ? classStyle('z4') : ''"
      >
        <div class="top">
          <div class="top-left">
            <h1>服务支持</h1>
            <span> Service</span>
          </div>
          <div class="top-right"></div>
        </div>
        <div class="service-content">
          <div class="content-list">
            <div class="ot">
              <div
                class="img-text"
                @click="toService"
                v-for="item in 2"
                :key="item"
              >
                <h3>
                  <img
                    src="https://resources.jsmo.xin/templates/upload/3785/201708/1502346587341.png"
                    alt=""
                  />
                  除雾霾
                </h3>
                <span></span>
                <p>净化pm2.5效率高达99.96%</p>
              </div>
            </div>
            <div class="ob">
              <div class="img-text" v-for="item in 3" :key="item">
                <h3>
                  <img
                    src="https://resources.jsmo.xin/templates/upload/3785/201708/1502346611866.png"
                    alt=""
                  />
                  超智能
                </h3>
                <span></span>
                <p>人性化只能体检，数据看得见</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 相关活动 -->
      <!-- <div
        class="activity mlist-zj"
        id="z5"
        :class="scrollTop > 0 ? classStyle('z5') : ''"
      >
        <div class="top">
          <div class="top-left">
            <h1>相关活动</h1>
            <span> Activity</span>
          </div>
          <div class="top-right"></div>
        </div>
        <div class="activity-content">
          <div class="activity-left">
            <div class="top">
              <img
                src="https://resources.jsmo.xin/templates/upload/3785/201708/1503552721509.jpg"
                alt=""
              />
              <div class="text-more">
                <div class="left">
                  <h1>打造专属空气净化器</h1>
                  <p>Warm Prompt</p>
                </div>
                <div class="right">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="bottom-item" v-for="item in 2" :key="item">
                <img
                  src="	https://resources.jsmo.xin/templates/upload/3785/201708/1503552739914.jpg"
                  alt=""
                />
                <div class="text-more">
                  <div class="left">
                    <h1>打造专属空气净化器</h1>
                    <p>Warm Prompt</p>
                  </div>
                  <div class="right">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="activity-right">
            <img
              src="https://resources.jsmo.xin/templates/upload/3785/201708/150346086578.jpg"
              alt=""
            />
            <div class="text-more">
              <div class="left">
                <h1>打造专属空气净化器</h1>
                <p>Warm Prompt</p>
              </div>
              <div class="right">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 相关资讯 -->
      <!-- <div
        class="information mlist-zj"
        id="z6"
        :class="scrollTop > 0 ? classStyle('z6') : ''"
      >
        <div class="top">
          <div class="top-left">
            <h1>相关资讯</h1>
            <span> Information</span>
          </div>
          <div class="top-right">
            <div
              class="right-item"
              :class="index == rightIndex ? 'active' : ''"
              v-for="(item, index) in informationList"
              :key="item.id"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
        <el-carousel
          height="242px"
          trigger="click"
          arrow="never"
          ref="bannerRef"
          :autoplay="false"
        >
          <el-carousel-item
            class="el-carousel-item"
            v-for="item in 4"
            :key="item"
          >
            <div
              class="information-item"
              @click="toDetail1"
              v-for="val in 2"
              :key="val"
            >
              <div class="left">
                <h1>空气中到底有哪些污染物？</h1>
                <h3>2022-7-12</h3>
                <p>
                  室内空气污染物一般包括污染气体和悬浮物，例如细菌、病毒、灰尘、花粉、霉菌孢子...
                </p>
                <div class="btn-more">
                  MORE <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div class="right">
                <img
                  src="https://resources.jsmo.xin/templates/upload/3785/201708/150302369983.jpg"
                  alt=""
                />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="arrow-quan">
          <div
            class="quan-item"
            @click="tabQuan1(index)"
            :class="index == tabIndex ? 'activeQuan' : ''"
            v-for="(item, index) in 2"
            :key="item"
          ></div>
        </div>
      </div> -->
      <!-- 底部 -->
      <div class="information-bottom">
        <div class="top">
          <div class="top-item">
            <h1>广州市纬拓科技有限公司</h1>
            <p>
              <i class="iconfont icon-weixin"
                ><img src="../static/img/图片1.png" alt=""
              /></i>
              <i class="iconfont icon-douyin"
                ><img src="../static/img/图片2.png" alt=""
              /></i>
            </p>
          </div>
          <div class="top-item">
            <p>地址：广东省广州市番禺区为民西路3号 金樟新城5栋</p>
            <p>电话：+86-15728736071</p>
          </div>
          <div class="top-item">
            <p>邮箱：gzwatt@139.com</p>
            <p>网站地址：</p>
            <p>www.gzwatt.com</p>
            <p>www.gzwatt.cn</p>
          </div>
        </div>
      </div>
      <!-- <div class="bottom-box">
        <div class="left" @click="leftClick">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="imagelink">
          <div class="imagelink-content" id="imagelink-content">
            <div class="img-item" v-for="item in 15" :key="item">
              <img
                src="	https://resources.jsmo.xin/templates/upload/1872/201703/1488345823656.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="right" @click="rightClick">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import heHander from "../../components/he-hander.vue";
export default {
  components: {
    heHander,
  },
  data() {
    return {
       list:[
        {id:1,title:'3D扫描仪帮助雕刻家展现无穷创造力，并节省大量项目时间',
        img:require('../static/img/anli/1.jpg')},
        {id:2,title:'AutoScan Inspec新升级，实现小尺寸精密工件三维扫描的高阶应用',img:require('../static/img/anli/2.jpg')},
        {id:3,title:'为军事博物馆制作超现实3D数字模型',img:require('../static/img/anli/3.jpg')},
        {id:4,title:'从战国到元明清 三维扫描仪精细还原古文物细节',img:require('../static/img/anli/4.jpg')},
      ],
      bannerList: [
        {
          id: 1,
          type: "video",
          img: require("../static/video/1、先临视频.mp4"),
        },
        { id: 2, type: "img", img: require("../static/img/1.jpg") },
        { id: 3, type: "img", img: require("../static/img/2.jpg") },
        { id: 3, type: "img", img: require("../static/img/banner3.jpg") },
      ],
      chanpingList: [
        {
          id: 1,
          title: "三维扫描服务",
          text: "工业制造</br> 艺术文博</br> 娱乐消费</br> 科学教育</br>三维可视化",
          img: require("../static/img/3.jpg"),
          url: "/relatedCases/relatedCases",
        },
        {
          id: 2,
          title: "产品中心",
          text: "Artec</br> 3D </br> 先临&天远</br> SCANTECH</br>Shapr3D</br>...... ",
          img: require("../static/img/4.jpg"),
          url: "/introduce/introduce",
        },
        {
          id: 3,
          title: "3D展示平台",
          text: "3D在线展示平台",
          img: require("../static/img/logo.jpg"),
        },
      ],
      height: "",
      scrollTop: 0,
      bottomIndex: 0,
      input: "",
      textarea1: "",
      tabIndex: 0,
      rightIndex: 0,
      informationList: [
        { id: "1", title: "全部" },
        { id: "2", title: "最新" },
      ],
      M_rightList: [
        { id: "1", title: "全部" },
        { id: "2", title: "分类2" },
      ],
      T_rightList: [
        { id: "1", title: "全部" },
        { id: "2", title: "二级分类" },
        { id: "3", title: "家用净化器" },
        { id: "4", title: "车载净化器" },
      ],
    };
  },
  methods: {
    rightClick1(index) {
      this.rightIndex = index;
    },
    // 服务详情
    toService() {
      this.$router.push("/serviceDetail/serviceDetail");
    },
    // 产品详情
    toDetail(item) {
      if (item.url) {
        this.$router.push(item.url);
      } else {
        window.open("https://www.gzwatt.cn");
      }
    },
    // 咨询详情
    toDetail1() {
      this.$router.push("/seekDetail/seekDetail");
    },
    // 案例详情
    toDetailCase() {
      this.$router.push("/caseDetail/caseDetail");
    },
    tabQuan(index) {
      this.tabIndex = index;
      let dom = document.getElementById("content-list");
      let left = 378;
      left = left * index + "px";
      dom.style = `left:-${left};transition: all 0.36s ease;`;
    },
    tabQuan1(index) {
      this.tabIndex = index;
      this.$refs.bannerRef.setActiveItem(index);
    },
    rightClick() {
      let dom = document.getElementById("imagelink-content");
      console.log(this.bottomIndex);
      if (this.bottomIndex == 7) {
        this.bottomIndex = 0;
        dom.style = `left:0;transition: 0s;`;
        return;
      }
      this.bottomIndex++;

      let left = 146;
      left = left * this.bottomIndex + "px";
      dom.style = `left:-${left};transition: all 0.36s ease;`;
    },
    leftClick() {
      let dom = document.getElementById("imagelink-content");
      let left = 146;
      if (this.bottomIndex == 0) {
        this.bottomIndex = 8;
        dom.style = `left:-${left};transition: 0s;`;
     
      }
      this.bottomIndex--;

      left = left * this.bottomIndex + "px";
      dom.style = `left:-${left};transition: all 0.36s ease;`;
    },
    windowScroll() {
      let clientHeight = document.body.clientHeight;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
  },
  watch: {},
  computed: {
    classStyle() {
      return (id) => {
        let clientHeight = document.body.clientHeight;
        let dom = document.getElementById(id);
        this.scrollTop;
        // console.log(dom.offsetTop);
        if (clientHeight + this.scrollTop > dom.offsetTop) {
          return "animate__animated animate__slideInUp";
        }
      };
    },
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScroll); //销毁滚动事件
  },
};
</script>
<style lang='less' scoped>
@import "../static/css/list-item/style.3785.css";

.content-box {
  // height: calc(100vh - 170px);
  padding-top: 80px;
  overflow: auto;
  .content-warrp {
    min-width: 1200px;
    /deep/ .el-carousel__arrow {
      background: #fff;
      // opacity: 0.5;
      color: #ccc;
      font-size: 15px;
    }
    /deep/ .el-carousel__arrow:hover {
      box-shadow: 0px 0px 14px 1px rgba(40, 40, 40, 0.5);
    }
    /deep/ .el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    /deep/ .el-carousel__indicators--horizontal {
      bottom: 20px;
    }
    .img-item {
      width: 100%;
      height: 100%;
    }
  }
  .mlist-zj {
    width: 1170px;
    padding-top: 60px;
    margin: 0 auto;
    margin-bottom: 60px;
    .top {
      display: flex;
      justify-content: space-between;
      .top-left {
        display: flex;
        h1 {
          font-size: 24px;
          line-height: 30px;
          color: #555;
          margin-right: 20px;
        }
        span {
          font-size: 14px;
          color: #bbb;
          line-height: 41px;
        }
      }
      .top-right {
        display: flex;
        .right-item {
          color: rgba(113, 113, 113, 0.64);
          font-size: 13px;
          padding: 0px 20px;
          border-radius: 50px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          cursor: pointer;
        }
        .right-item:hover {
          border-color: #6388cf;
          background-color: #6388cf;
          color: #fff;
        }
        .active {
          background-color: #6388cf;
          color: #fff;
        }
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      .content-item:nth-child(3n + 3) {
        margin-right: 0px;
      }
      .content-item:hover img {
        transform: scale(1.1);
        transition: all 0.36s ease;
      }
      .content-item {
        transition: all 0.36s ease;
        width: 376px;
        height: 660px;

        cursor: pointer;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        border-radius: 10px;
        box-shadow: 0 0 20px rgb(0 0 0 / 30%);
        overflow: hidden;
        .img-box {
          position: relative;
          overflow: hidden;
          width: 376px;
          height: 280px;
          img {
            width: 376px;
            height: 280px;
            // border-radius: 4px;
            position: relative;
            transition: all 0.36s ease;
          }
        }

        .text-box {
          padding: 10px 20px;
          height: 300px;
          h3 {
            color: #222;
            font-size: 24px;
            text-align: center;
            line-height: 30px;
            margin-bottom: 30px;
          }
          p {
            color: #666;
            font-size: 16px;
            text-align: center;
            transition: all 0.3s ease;
            line-height: 30px;
          }
        }
        .more {
          margin-left: 30px;
          font-size: 14px;
          color: #999;
        }
      }
    }
    .mlist-more:hover h4 {
      color: #fff;
      transition: all 0.36s ease;
      margin-right: 10px;
    }
    .mlist-more:hover i {
      color: #fff;
      transition: all 0.36s ease;
    }
    .mlist-more:hover {
      background: #6388cf;
      transition: all 0.36s ease;
    }

    .mlist-more {
      transition: all 0.36s ease;
      width: 200px;
      height: 50px;
      text-align: center;
      margin: 50px auto 0;
      margin-bottom: 160px;
      font-size: 14px;
      background-color: transparent;
      border: 1px solid rgba(170, 170, 170, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      h4 {
        transition: all 0.36s ease;
      }
      i {
        transition: all 0.36s ease;
        color: transparent;
      }
    }
  }
}
.mcustomize-zj {
  // background: url("https://resources.jsmo.xin/templates/upload/3785/201708/1503390077444.jpg");
  background: rgb(243, 244, 246);
  background-position: initial;
  background-size: cover;
  background-repeat: no-repeat;
  // height: 500px;
  padding-top: 140px;
  padding-bottom: 100px;
  .mcustomize-content {
    width: 1070px;
    display: flex;
    height: 350px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    box-shadow: 0 10px 27px rgb(0 0 0 / 8%);
    position: relative;
    top: 0px;
    .left {
      width: 420px;
      height: 350px;
      img {
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
      }
    }
    // .left::after {
    //   content: " ";
    //   width: 460px;
    //   height: 250px;
    //   background-color: #607cb3;
    //   position: absolute;
    //   z-index: 0;
    //   top: 140px;
    //   left: -40px;
    //   display: inline-block;
    //   border-radius: 2px;
    //   box-shadow: -7px 3px 40px rgb(0 0 0 / 16%);
    // }
    .right {
      flex: 1;
      padding: 30px 50px;
      box-sizing: border-box;
      h1 {
        font-size: 24px;
        line-height: 30px;
        color: #555;
        margin-bottom: 20px;
        span {
          color: #7c7c7c;
          font-size: 14px;
        }
      }
      p {
        color: rgba(103, 103, 103, 0.74);
        line-height: 28px;
        font-size: 14px;
      }
    }
  }
}
.model-zj {
  padding-bottom: 0px;
  padding-top: 160px;
  .model-content {
    width: 1170px;
    height: 303px;
    padding-top: 50px;
    box-sizing: border-box;
    overflow: hidden;
    .content-list {
      display: flex;
      width: 2000px;
      position: relative;
      left: 0px;
      .list-item:hover .bottom {
        height: 252px;
        transition: all 0.36s ease;
        background: #6388cf;
        padding: 50px 30px;
      }
      .list-item:hover .quan {
        bottom: 30px;
        transition: all 0.36s ease;
        background: #fff;
      }
      .list-item:hover .bottom > p {
        color: #e0e7f5;
      }
      .list-item:hover .bottom > h1 {
        color: #fff;
      }
      .list-item:hover .bottom > h3 {
        color: rgba(255, 255, 255, 0.5);
      }
      .list-item {
        width: 378px;
        position: relative;
        height: 252px;
        margin-right: 18px;
        cursor: pointer;
      }
      img {
        height: 140px;
        width: 100%;
      }
      .bottom {
        height: 113px;
        overflow: hidden;
        padding: 30px 30px;
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        transition: all 0.36s ease;
        h1 {
          transition: all 0.36s ease;
          color: #333;
          font-size: 16px;
          line-height: 30px;
          width: 90%;
        }
        h3 {
          transition: all 0.36s ease;
          color: #777;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          width: 40%;
          overflow: hidden;
          color: rgba(0, 0, 0, 0.42);
          margin-bottom: 20px;
          width: 90%;
        }

        p {
          color: #fff;
          line-height: 28px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          width: 90%;
        }
      }
      .quan {
        width: 37px;
        height: 37px;
        border-radius: 50%;
        border: 1px solid #ccc;
        position: absolute;
        bottom: 50px;
        right: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.36s ease;
      }
    }
  }
  .arrow-quan {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    // padding-bottom: 160px;
    .quan-item:hover {
      background: #6388cf;
      transition: all 0.36s ease;
    }
    .quan-item {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid #6388cf;
      margin-right: 20px;
      transition: all 0.36s ease;
      cursor: pointer;
    }
    .activeQuan {
      background: #6388cf;
    }
  }
}
.service {
  .top {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .top-left {
      display: flex;
      h1 {
        font-size: 24px;
        line-height: 30px;
        color: #555;
        margin-right: 20px;
      }
      span {
        font-size: 14px;
        color: #bbb;
        line-height: 41px;
      }
    }
  }
  .service-content {
    margin-top: 50px;
    width: 100%;
    height: 697px;
    background: url("https://resources.jsmo.xin/templates/upload/3785/201708/1502331338154.jpg");
    background-position: center center;
    background-size: initial;
    background-repeat: no-repeat;
    background-size: initial;
    background-size: cover;
    background-attachment: fixed;
    .content-list {
      width: 1200px;
      margin: 0 auto;
      padding-top: 200px;
      box-sizing: border-box;
      .ot {
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        .img-text:hover {
          transform: scale(1.1);
          transition: all 0.36s ease;
          background: #588df4;
          border-radius: 5px;
          border: none;
        }
        .img-text:hover span {
          width: 150px;
          transition: all 0.36s ease;
        }
        .img-text {
          cursor: pointer;
          width: 292px;
          height: 214px;
          border: 1px solid #ccc;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: all 0.36s ease;
          h3 {
            align-self: baseline;
            font-size: 18px;
            color: #fff;
            display: flex;
            align-items: center;
            margin-left: 75px;
            img {
              width: 41px;
              height: 41px;
              margin-right: 10px;
            }
          }
          span {
            width: 41px;
            height: 1px;
            background: rgba(255, 255, 255, 0.62);
            margin-top: 20px;
            margin-bottom: 20px;
            align-self: baseline;
            margin-left: 75px;
            transition: all 0.36s ease;
          }
          p {
            color: rgba(255, 255, 255, 0.62);
            font-size: 14px;
          }
        }
      }
    }

    .ob {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .img-text:hover {
        transform: scale(1.1);
        transition: all 0.36s ease;
        background: #588df4;
        border-radius: 5px;
        border: none;
      }
      .img-text:hover span {
        width: 150px;
        transition: all 0.36s ease;
      }
      .img-text {
        width: 292px;
        height: 214px;
        border: 1px solid #ccc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        h3 {
          align-self: baseline;
          font-size: 18px;
          color: #fff;
          display: flex;
          align-items: center;
          margin-left: 75px;
          img {
            width: 41px;
            height: 41px;
            margin-right: 10px;
          }
        }
        span {
          width: 41px;
          height: 1px;
          background: rgba(255, 255, 255, 0.62);
          margin-top: 20px;
          margin-bottom: 20px;
          align-self: baseline;
          margin-left: 75px;
          transition: all 0.36s ease;
        }
        p {
          color: rgba(255, 255, 255, 0.62);
          font-size: 14px;
        }
      }
    }
  }
}
.activity > .top {
  padding-bottom: 50px;
}
.activity {
  .activity-content {
    display: flex;

    .activity-left {
      .top:hover .text-more {
        bottom: 0px;
        transition: all 0.36s ease;
      }
      .top:hover img {
        transform: scale(1.1);
        transition: all 0.36s ease;
      }
      .top {
        width: 570px;
        height: 276px;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }

        .text-more {
          height: 80px;
          // height: 0px;
          width: 100%;
          background: #6388cf;
          position: absolute;
          bottom: -80px;
          overflow: hidden;
          display: flex;
          justify-content: space-between;
          padding: 0px 30px;
          align-items: center;
          box-sizing: border-box;
          transition: all 0.36s ease;
          .left {
            h1 {
              font-size: 16px;
              color: #fff;
              margin-bottom: 10px;
            }
            p {
              font-size: 13px;
              color: rgba(255, 255, 255, 0.66);
            }
          }
          .right {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 0;
          }
        }
      }

      .bottom {
        width: 570px;
        height: 276px;
        display: flex;
        .bottom-item:hover .text-more {
          bottom: 0px;
          transition: all 0.36s ease;
        }
        .bottom-item:hover img {
          transform: scale(1.1);
          transition: all 0.36s ease;
        }
        .bottom-item {
          width: 285px;
          height: 100%;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
          .text-more {
            height: 80px;
            // height: 0px;
            width: 100%;
            background: #6388cf;
            position: absolute;
            bottom: -80px;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            padding: 0px 30px;
            align-items: center;
            box-sizing: border-box;
            transition: all 0.36s ease;
            .left {
              h1 {
                font-size: 16px;
                color: #fff;
                margin-bottom: 10px;
              }
              p {
                font-size: 13px;
                color: rgba(255, 255, 255, 0.66);
              }
            }
            .right {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              bottom: 0;
            }
          }
        }
      }
    }
    .activity-right:hover .text-more {
      bottom: 0px;
      transition: all 0.36s ease;
    }
    .activity-right:hover img {
      transform: scale(1.1);
      transition: all 0.36s ease;
    }
    .activity-right {
      width: 600px;
      height: 552px;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      .text-more {
        height: 80px;
        // height: 0px;
        width: 100%;
        background: #6388cf;
        position: absolute;
        bottom: -80px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        padding: 0px 30px;
        align-items: center;
        box-sizing: border-box;
        transition: all 0.36s ease;
        .left {
          h1 {
            font-size: 16px;
            color: #fff;
            margin-bottom: 10px;
          }
          p {
            font-size: 13px;
            color: rgba(255, 255, 255, 0.66);
          }
        }
        .right {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 0;
        }
      }
    }
  }
}
.information {
  padding-bottom: 160px;
  .top {
    padding-bottom: 50px;
  }
  .el-carousel-item {
    display: flex;
    justify-content: space-between;
  }

  .information-item:hover .left > .btn-more {
    background: #6388cf;
    color: #fff;
    transition: all 0.36s ease;
  }
  .information-item:hover .right > img {
    transform: scale(1.1);
    transition: all 0.36s ease;
  }
  .information-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 570px;
    border: 1px solid rgba(170, 170, 170, 0.2);
    .left {
      padding: 40px;
      box-sizing: border-box;
      flex: 1;
      h1 {
        transition: all 0.36s ease;
        color: #333;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
      }
      h3 {
        font-size: 13px;
        line-height: 26px;
        color: #888;
      }
      p {
        font-size: 13px;
        line-height: 26px;
        height: 52px;
        overflow: hidden;
        color: #999;
      }
      .btn-more {
        width: 92px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        line-height: 32px;
        border: 1px solid rgba(170, 170, 170, 0.2);
        border-radius: 5px;
        transition: all 0.36s ease;
        margin-top: 30px;
        i {
          margin-left: 10px;
        }
      }
    }
    .right {
      width: 180px;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .arrow-quan {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    // padding-bottom: 160px;
    .quan-item:hover {
      background: #6388cf;
      transition: all 0.36s ease;
    }
    .quan-item {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid #6388cf;
      margin-right: 20px;
      transition: all 0.36s ease;
      cursor: pointer;
    }
    .activeQuan {
      background: #6388cf;
    }
  }
}
.information-bottom {
  padding-bottom: 100px;
  background: #f7f7f7;
  .top {
    width: 1200px;
    margin: 0 auto;
    padding-top: 60px;
    display: flex;
    .top-item:nth-child(3) {
      border-right: none;
    }
    .top-item:nth-child(1) {
      align-items: baseline;
    }
    .top-item {
      border-right: 1px solid #ccc;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-size: 16px;
        margin-bottom: 40px;
      }
      p {
        display: flex;
        line-height: 30px;
        width: 100%;
        padding-left: 20px;
        box-sizing: border-box;
        i:hover {
          opacity: 1;
          transition: all 0.36s ease;
        }
        i:hover img {
          opacity: 1;
          transition: all 0.36s ease;
          display: block;
        }
        i {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #bdbdbd;
          margin-right: 30px;
          opacity: 0.6;
          transition: all 0.36s ease;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          img {
            position: absolute;
            width: 100px;
            height: 100px;
            top: 32px;
            display: none;
          }
        }
      }
    }
  }
  .inp-fa {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    margin-top: 100px;
    margin-bottom: 70px;
    .left {
      width: 350px;
      margin-right: 50px;
      /deep/ .el-input__inner {
        background: #e4e4e4;
        border: none;
        opacity: 0.5;
      }
      /deep/ .el-input {
        width: 100%;
        height: 40px;
        margin-bottom: 10px;
      }
    }
    .right {
      flex: 1;
      /deep/ .el-textarea__inner {
        border: none;
        width: 100%;
        height: 140px !important;
        background: #e4e4e4;
        border-radius: 0.3125rem;
        resize: none;
        opacity: 0.5;
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    padding-bottom: 320px;
    .btn:hover {
      background: #6388cf;
      color: #fff;
      transition: all 0.36s ease;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      width: 280px;
      height: 56px;
      border-radius: 56px;
      border: 1px solid #ccc;
      transition: all 0.36s ease;
    }
  }
}
.bottom-box {
  padding-top: 100px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .imagelink {
    width: 1200px;
    overflow: hidden;
    // margin: 0 auto;

    .imagelink-content {
      display: flex;
      width: 3000px;
      position: relative;
      left: 0px;
      .img-item {
        width: 146px;
        height: 66px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.bottom-box > .left {
  // padding-left: 30px;
  box-sizing: border-box;
  cursor: pointer;
}
.bottom-box > .right {
  // padding-left: 30px;
  box-sizing: border-box;
  cursor: pointer;
}
</style>