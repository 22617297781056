<template>
  <div>
    <div class="hander-zj" id="hander">
      <div class="left" @click="toHome" style="cursor:pointer">
        <img
          src="../views/static/img/logo.png"
          alt=""
        />
      </div>
      <div class="right">
        <div
          class="right-item"
          :class="topIndex == index ? 'activeTop' : ''"
          @click="toNav(item)"
          v-for="(item, index) in rightList"
          :key="item.id"
        >
          <p style="overflow: hidden" class="p1" v-if="!item.hover">
            <span :data-title="item.title">{{ item.title }} </span
            ><i v-if="item.hover" class="el-icon-arrow-down"></i>
          </p>

          <el-popover
            v-if="item.hover"
            placement="bottom"
            width="200"
            trigger="hover"
            :visible-arrow="false"
            popper-class="ppppp"
          >
            <div class="gywm-box">
              <p
                v-for="val in gywmList"
                class="p-item"
                @click="toPage(val)"
                :key="val.id"
              >
                {{ val.title }}
              </p>
            </div>
            <p
              style="overflow: hidden"
              slot="reference"
              class="p1"
              v-if="item.hover"
            >
              <span :data-title="item.title">{{ item.title }} </span
              ><i v-if="item.hover" class="el-icon-arrow-down"></i>
            </p>
          </el-popover>
        </div>
      </div>
    </div>
     <div class="right-fixed">
      <div class="fixed-item">
        <span class="iconfont icon-weixin"></span>
        <img src="../views/static/img/图片1.png" alt="">
      </div>
    
      <div class="fixed-item">
        <span class="iconfont icon-douyin"></span>
         <img src="../views/static/img/图片2.png" alt="">
      </div>
      <div class="fixed-item" @click="toTop" v-if="scrollTop>0">
        <span class="el-icon-arrow-up"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    topIndex: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      scrollTop:0,
      rightList: [
        { id: "1", hover: false, title: "首页", url: "/" },
        {
          id: "2",
          hover: false,
          title: "产品中心",
          url: "/introduce/introduce",
        },
        {
          id: "3",
          hover: false,
          title: "案例展示",
          url: "/relatedCases/relatedCases",
        },
        { id: "4", hover: false, title: "3D平台" },
        // { id: "5", hover: false, title: "相关资讯", url: "/service/service" },
        {
          id: "6",
          hover: false,
          title: "联系我们",
          url: "/contactWe/contactWe",
        },
      ],
      gywmList: [
        { id: 1, title: "关于我们", url: "/aboutUs/aboutUs" },
        { id: 2, title: "服务支持", url: "/serviceSupport/serviceSupport" },
      ],
    };
  },
  methods: {
    toHome(){
      this.$router.push('/');
    },
     toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    toNav(item) {
      if (item.url) {
        this.$router.push(item.url);
      }else{
       window.open('https://www.gzwatt.cn')
      }
      
    },
    toPage(item) {
      if (item.url) {
        this.$router.push(item.url);
      }
    },
    // 监听页面滚动 获取滚动条位置
    windowScroll() {
      let clientHeight = document.body.clientHeight;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      let hander = document.getElementById("hander");

      if (this.scrollTop > 0) {
        hander.style = `height:70px;transition: all 0.36s ease;`;

      } else {
        hander.style = `height:80px;transition: all 0.36s ease;`;
      
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
  },
};
</script>
<style lang='less' scoped>
@import "../views/iconfont/iconfont.css";
.ppppp{
  top: 100px;
}
.right-fixed {
  position: fixed;
  right: 0;
  z-index: 10000;
  bottom: 50px;
}
.fixed-item:hover img{
  display: block;

}
.fixed-item {
  width: 40px;
  height: 40px;
  border: 1px solid #e6e6e6;
  display: block;
  font-size: 16px;
  text-align: center;
  position: relative;
  transition: all 0.3s ease-out 0s;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img{
    position: absolute;
    width: 100px;
   left: -106px;
    height: 100px;
    display: none;
  }

}
.fixed-item:nth-child(1) {
  border-bottom: none;
}

.fixed-item:nth-child(3) {
  border-top: none;
}
.fixed-item:hover .iconfont {
  color: #fff;
}
.fixed-item:hover .el-icon-arrow-up{
  color: #fff;
}
.fixed-item:hover {
  background: #6388cf;
}
.gywm-box {
  .p-item {
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .p-item:hover {
    background: #6388cf;
    color: #fff;
  }
}

.product-box {
  top: -80px;
}
.hander-zj {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  position: fixed;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1111;
  background: #fff;

  .left {
    img {
      // width: 138px;
      height: 30px;
    }
  }

  .right {
    display: flex;
    height: 100%;
    align-items: center;
    .activeTop {
      color: #607cb3 !important;
    }
    .right-item {
      height: 100%;

      margin: 0 20px;
      cursor: pointer;
      color: #666;
      font-size: 14px;
      display: flex;
      align-items: center;
      //   overflow: hidden;
      position: relative;
    }
    .p1 {
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 40px;
      transition: transform 0.3s linear, -webkit-transform 0.3s linear;
    }
    span {
      position: relative;
      transition: transform 0.3s linear, -webkit-transform 0.3s linear;

      overflow: hidden;
    }
    span:before {
      content: attr(data-title);
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      text-align: center;
    }

    .p1:hover span {
      overflow: initial;
      color: #7193d3;
      transform: translateY(-20px);
    }
    .p1:hover i {
      color: #7193d3;
    }
    i {
      margin-left: 5px;
    }
  }
}
</style>