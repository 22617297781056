import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: ' ',
      keepAlive: true,//此组件需要被缓存
    },
  },
  {
    path: '/introduce/introduce',
    name: 'introduce',
    component: () => import('../views/introduce/introduce.vue')//产品中心
  },
  {
    path: '/introduceDetail/introduceDetail',
    name: 'introduceDetail',
    component: () => import('../views/introduceDetail/introduceDetail.vue')//产品详情
  },
  {
    path: '/relatedCases/relatedCases',
    name: 'relatedCases',
    component: () => import('../views/relatedCases/relatedCases.vue')//相关案例
  },
  {
    path: '/service/service',
    name: 'service',
    component: () => import('../views/service/service.vue')//相关咨询
  },
  {
    path: '/contactWe/contactWe',
    name: 'contactWe',
    component: () => import('../views/contactWe/contactWe.vue')//联系我们
  },{
    path: '/caseDetail/caseDetail',
    name: 'caseDetail',
    component: () => import('../views/caseDetail/caseDetail.vue')//案例详情
  },
  {
    path: '/serviceDetail/serviceDetail',
    name: 'serviceDetail',
    component: () => import('../views/serviceDetail/serviceDetail.vue')//服务支持详情
  },
  {
    path: '/aboutUs/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs/aboutUs.vue')//关于我们
  },
  {
    path: '/serviceSupport/serviceSupport',
    name: 'serviceSupport',
    component: () => import('../views/serviceSupport/serviceSupport.vue')//服务支持 serviceSupport  seek 
  },
  {
    path: '/seekDetail/seekDetail',
    name: 'seekDetail',
    component: () => import('../views/seekDetail/seekDetail.vue')//咨询详情 seekDetail
  },
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  //  // 刷新页面回归顶部
  //  scrollBehavior(to, from, savedPosition) {
  //   // 期望滚动到哪个位置
  //   return {
  //     x: 0,
  //     y: 0
  //   }
  // },
})
// 跳转页面到顶部
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})
export default router
