<template>
  <div class="uemo">
    <listitem></listitem>

  </div>
</template>

<script>
// @ is an alias to /src
// import './static/js/app.js'
// import './static/js/jquery.dll.js'
// import './static/js/vender.js'
import listitem from "./list-item/list-item.vue";
export default {
  name: "HomeView",
  components: {
    listitem,
  },
  data(){
        return{
            scrollTop:0
        }
  },
  mounted() {
   
  },
  methods: {
  }
};
</script>
<style scoped>

</style>
