import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import 'animate.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './views/iconfont/iconfont.css';
import './views/static/css/index.css'


import BaiduMap from 'vue-baidu-map'
// // px2rem 自适应
//适配
import "./components/lib-flexible/flexible.js";
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'bGKvoOC5GYGLjLW9hhx5B25VEE2FGIoG'
  
})

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
